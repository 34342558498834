
















































































































































































import Vue from 'vue';
import * as Api from '@/api/order';
import moment from 'moment';
import { downloadFile } from '@/utils/index';
const columns = [
  {
    title: '开票订单号',
    dataIndex: 'invoiceOrderNumber',
    key: 'invoiceOrderNumber',
    align: 'center',
    scopedSlots: { customRender: 'invoiceOrderNumber' },
  },
  {
    title: '发票抬头',
    dataIndex: 'enterpriseName',
    key: 'enterpriseName',
    align: 'center',
    scopedSlots: { customRender: 'enterpriseName' },
  },
  {
    title: '发票类型',
    dataIndex: 'invoiceType',
    key: 'invoiceType',
    align: 'center',
    scopedSlots: { customRender: 'invoiceType' },
  },
  {
    title: '开票金额(元)',
    dataIndex: 'invoiceAmount',
    key: 'invoiceAmount',
    align: 'center',
    scopedSlots: { customRender: 'invoiceAmount' },
  },
  {
    title: '发票介质',
    dataIndex: 'invoiceMedium',
    key: 'invoiceMedium',
    align: 'center',
    scopedSlots: { customRender: 'invoiceMedium' },
  },
  {
    title: '租户类型',
    dataIndex: 'agencyType',
    key: 'agencyType',
    align: 'center',
    scopedSlots: { customRender: 'agencyType' },
  },
  {
    title: '开票账号',
    dataIndex: 'billingAccount',
    key: 'billingAccount',
    align: 'center',
    scopedSlots: { customRender: 'billingAccount' },
  },
  {
    title: '备注',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    scopedSlots: { customRender: 'description' },
  },
  // {
  //   title: '省市区',
  //   dataIndex: 'combinationAddress',
  //   key: 'combinationAddress',
  //   align: 'center',
  //   scopedSlots: { customRender: 'combinationAddress' },
  // },
  {
    title: '申请时间',
    dataIndex: 'recDate',
    key: 'recDate',
    align: 'center',
    scopedSlots: { customRender: 'recDate' },
  },
  {
    title: '发票状态',
    dataIndex: 'invoiceStatus',
    key: 'invoiceStatus',
    align: 'center',
    scopedSlots: { customRender: 'invoiceStatus' },
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
const dataSource = [];
export default Vue.extend({
  name: 'invoiceList',
  data() {
    return {
      tableLoading: false,
      formState: {
        invoiceOrderNumber: '',
        billingAccount: '',
        invoiceStatus: '0',
        agencyType: '',
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      dataSource,
      columns,
      dealVisible: false,
      dealData: '',
      applyTime: ['', ''],
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      selected: [], //选中的数据
      disabled: false,
    };
  },
  computed: {},
  mounted() {
    this.getInvoiceList();
  },
  methods: {
    moment,
    /**
     * @description: 批量按钮
     * @param {*}
     * @return {*}
     */
    getCheckboxProps: (record) => ({
      props: {
        disabled: record.invoiceStatus === 1, // Column configuration not to be checked
      },
    }),
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selected = selectedRows;
    },
    getInvoiceList() {
      const params = {
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        invoiceOrderNumber: this.formState.invoiceOrderNumber,
        billingAccount: this.formState.billingAccount,
        queryStatus: this.formState.invoiceStatus,
        agencyType: this.formState.agencyType,
        startTime: this.applyTime[0],
        endTime: this.applyTime[1],
        orderField: 'rec_date',
        order: 'desc',
      };
      this.tableLoading = true;
      Api.bizOrderInvoice(params)
        .then((res) => {
          if (res.status === 200) {
            this.dataSource = res.result.list;
            this.pagination.total = res.result.total;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 组合查询表单提交
    handleSearchSubmit() {
      this.pagination.current = 1;
      this.getInvoiceList();
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getInvoiceList();
    },
    /**
     * @description: 导出
     * @param {*}
     * @return {*}
     **/
    exportButton() {
      const selectList: any = [];
      this.selected.map((obj: any) => {
        selectList.push(obj.invoiceOrderNumber);
      }); //筛选账号id
      const params = {
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        invoiceOrderNumber: this.formState.invoiceOrderNumber,
        billingAccount: this.formState.billingAccount,
        queryStatus: this.formState.invoiceStatus,
        startTime: this.applyTime[0],
        endTime: this.applyTime[1],
        orderField: 'rec_date',
        order: 'desc',
      };
      Api.listExport(params, selectList).then((res: any) => {
        downloadFile(res, '云算房技术服务费发票财务表');
      });
    },
    /**
     * @description: 批量一键处理按钮
     * @param {*}
     * @return {*}
     */
    aKeyProcessing() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.info('请先选择您要处理的发票信息');
      } else {
        const invoiceOrderNumList: any = [];
        this.selected.map((obj: any) => {
          invoiceOrderNumList.push(obj.invoiceOrderNumber);
        }); //筛选账号id
        this.dealVisible = false;
        const state = 1;
        Api.auditDetail(invoiceOrderNumList, state).then((res) => {
          if (res.status === 200) {
            this.$message.success('处理成功');
            this.selectedRowKeys = [];
            this.selected = [];
            this.handleSearchSubmit();
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    /**
     * @description: 取消处理按钮
     * @param {*}
     * @return {*}
     */
    deal(record) {
      this.dealVisible = true;
      this.dealData = record.invoiceOrderNumber;
    },
    dealTip() {
      this.dealVisible = false;
      const invoiceOrderNumList = [this.dealData];
      const state = 1;
      Api.auditDetail(invoiceOrderNumList, state).then((res) => {
        if (res.status === 200) {
          this.$message.info('处理成功');
          this.handleSearchSubmit();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    openVisible(record) {
      this.$router.push({
        path: '/invoice/invoiceDetail',
        query: {
          id: record.invoiceOrderNumber,
          state: record.invoiceStatus,
          combinationAddress: record.combinationAddress,
          address: record.address,
        },
      });
    },
    range(start, end) {
      const result: number[] = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    disabledRangeTime(_, type) {
      if (type === 'start') {
        return {
          disabledHours: () => this.range(0, 60).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        };
      }
      return {
        disabledHours: () => this.range(0, 60).splice(20, 4),
        disabledMinutes: () => this.range(0, 31),
        disabledSeconds: () => [55, 56],
      };
    },
  },
});
