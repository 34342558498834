/*
 * @Author: zhangguoliang
 * @Date: 2021-09-26 10:38:15
 * @LastEditors: wuxinxin
 * @LastEditTime: 2022-03-02 10:35:58
 */

import { Raw } from '@/interface';
import { message } from 'ant-design-vue';
import Vue from 'vue';
import { dict_list } from '@/api/common';

/**
 * @description: 从目标对象中取出模板对象所需的属性
 * @param {Raw} target 目标对象
 * @param {Raw} template 模板
 * @return {Raw}
 */
export function cloneWithTemplate(target: Raw, template?: Raw): Raw {
  if (!template) return target;
  return Object.keys(template).reduce<Raw>((prev, curr) => {
    prev[curr] = target[curr] || template[curr];
    return prev;
  }, {});
}

interface Return {
  start: () => void;
  cancel: () => void;
}

export function interval(callback: () => void, durtion = 0): Return {
  let requestId = 0;
  let start = 0;
  let stop = false;
  const loop = () => {
    const now = Date.now();
    if (now - start >= durtion) {
      callback();
      start = now;
    }
    if (!stop) {
      requestId = requestAnimationFrame(loop);
    }
  };
  return {
    start() {
      start = Date.now();
      stop = false;
      loop();
    },
    cancel() {
      stop = true;
      requestId && cancelAnimationFrame(requestId);
    },
  };
}

interface CountDownReturn {
  begin: () => void;
  finish: () => void;
  state: any;
}

export function countDown(count = 60): CountDownReturn {
  const state = Vue.observable({ current: count, running: false });
  const { start, cancel } = interval(() => {
    if (state.current > 1) {
      state.current--;
    } else {
      finish();
    }
  }, 1000);
  const begin = () => {
    if (state.running) return;
    start();
    state.running = true;
  };
  function finish() {
    cancel();
    state.current = count;
    state.running = false;
  }
  return {
    begin,
    finish,
    state,
  };
}

/**
 * @description: 校验文件格式
 * @param {*} fileName 文件名称
 * @param {*} type 文件类型
 * @return {*}
 */
export const checkFileType = (fileName, ...arg) => {
  const fileArr = arg.length ? arg.map((i) => i.toLowerCase()) : ['zip'];
  if (!fileName) return false;
  const fileType = fileName.substring(fileName.lastIndexOf('.') + 1);
  return fileArr.includes(fileType.toLowerCase());
};
/**
 * @description: 下载文件流
 * @param {*} data 文件流
 * @param {*} fileName 文件名
 * @param {*} fileType 文件类型
 * @return {*}
 */
export function downloadFile(
  data: ArrayBuffer,
  fileName = new Date().toString(),
  fileType = 'excel'
): void {
  let type = '';
  switch (fileType) {
    case 'excel':
      type =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
    case 'pdf':
      type = 'application/pdf';
      break;
    default:
      type = 'application/zip';
      break;
  }
  console.log('🚀 获取之后a', data, fileName, fileType);
  const resBlob = new Blob([data]);
  const reader = new FileReader();
  reader.readAsText(resBlob, 'utf-8');
  reader.onload = () => {
    try {
      const result = JSON.parse(reader.result as string);
      message.error(result.message);
      return '';
    } catch (error) {
      const blob = new Blob([data], { type });
      if ('download' in document.createElement('a')) {
        // 非IE下载
        const elink = document.createElement('a') as HTMLAnchorElement;
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = window.URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        window.URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        // IE10+下载
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, fileName);
        }
      }
    }
  };
}

/**
 * 两数相加之和,解决精度丢失问题
 * @param arg1
 * @param arg2
 * @returns number
 */
export function accAdd(arg1: number, arg2: number): number {
  let r1, r2;
  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }
  const m = Math.pow(10, Math.max(r1, r2));
  return (arg1 * m + arg2 * m) / m;
}

/**
 * 两数相减之和,解决精度丢失问题
 * @param arg1
 * @param arg2
 * @returns number
 */
export function subtract(arg1: number, arg2: number): string {
  if (isNaN(arg1) || isNaN(arg2)) return '';
  let r1, r2;
  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }
  const m = Math.pow(10, Math.max(r1, r2));
  const n = r1 >= r2 ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

// 日期格式转时间戳2023-04-01 12:00:00 处理非ISO标准格式
export function dateStringToTimestamp(str) {
  const [year, month, day, hour, minute, second] = str.split(/[- :]/);
  return new Date(year, month - 1, day, hour, minute, second).getTime(); // 注意月份减1
}

// 时间戳转日期格式2023-04-01 12:00:00
export function timestampFormatDate(timestamp) {
  let date = new Date(timestamp);
  let year = date.getFullYear();
  let month = "0" + (date.getMonth() + 1); // getMonth返回的月份是从0开始的
  let day = "0" + date.getDate();
  let hours = "0" + date.getHours();
  let minutes = "0" + date.getMinutes();
  let seconds = "0" + date.getSeconds();

  return year + "-" + month.substr(-2) + "-" + day.substr(-2) 
         + " " + hours.substr(-2) + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
}

// 获取字典列表
export function dictListCommon(dictCode) {
  return new Promise((resolve, reject) => {
    let params = {
      dictCode: dictCode
    }
    dict_list(params).then(res => {
      resolve(res.result.list)
    })
  })
}

// 获取字典label值
export function getDictLabel(dictList, entryValue) {
  let val = dictList.find((element) => element.entryValue === entryValue + '');
  if (val) {
    return val.entryKey
  } else {
    return entryValue
  }
}

// 获取字典class值
export function getDictLabelClass(entryValue) {
  let tagList=[
    {
      value:"0",
      class:"orange"
    },
    {
      value:"1",
      class:"red"
    },
    {
      value:"2",
      class:"blue"
    },
    {
      value:"3",
      class:"green"
    },
    {
      value:"4",
      class:"green"
    }
  ]
  let val = tagList.find((element) => element.value === entryValue + '');
  if (val) {
    return val.class
  } else {
    return "blue"
  }
}