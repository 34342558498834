/*
 * @Descripttion:
 * @Author: sunchen
 * @Date: 2021-06-15 15:44:23
 * @LastEditors: wuxinxin
 * @LastEditTime: 2022-03-02 12:46:23
 */
import request from '@/utils/request';
import { BaseResponse } from '@/interface';

const http = {
  /**
   * @description: get请求
   * @param {*}
   * @return {*}
   */
  get: <T>(
    url: string,
    params: Record<string, any> = {}
  ): Promise<BaseResponse<T>> => {
    return request({
      url: `${url}`,
      method: 'get',
      params,
      ...params,
    });
  },
  /**
   * @description: download请求
   * @param {*}
   * @return {*}
   */
  download: <T>(
    url: string,
    params: Record<string, any> = {},
    invoiceOrderNumList: Record<string, any> = []
  ): Promise<BaseResponse<T>> => {
    return request({
      url: `${url}`,
      method: 'post',
      responseType: 'blob',
      params,
      data: invoiceOrderNumList,
    });
  },
  /**
   * POST请求
   * @param {String} url - 接口地址
   */
  post: <T>(
    url: string,
    data: Record<string, any> = {}
  ): Promise<BaseResponse<T>> => {
    return request({
      url: `${url}`,
      method: 'post',
      data,
    });
  },
  /**
   * put请求
   * @param {String} url - 接口地址
   */
  put: <T>(
    url: string,
    data: Record<string, any> = {}
  ): Promise<BaseResponse<T>> => {
    return request({
      url: `${url}`,
      method: 'put',
      data,
    });
  },
  /**
   * @description: delete请求
   * @param {String} url
   * @return {*}
   */
  delete: <T>(
    url: string,
    data: Record<string, any> = {}
  ): Promise<BaseResponse<T>> => {
    return request({
      url: `${url}`,
      method: 'delete',
      data,
    });
  },
};

export default http;
