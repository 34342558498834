/*
 * @Descripttion:
 * @Author: wuxinxin
 * @Date: 2021-12-06 14:03:58
 * @LastEditors: yuyang
 * @LastEditTime: 2022-05-07 17:15:48
 */
import http from '@/utils/http';
import request from '@/utils/request';
import { BaseResponse } from '@/interface';

//  发票列表查询
export function bizOrderInvoice(formdata: any): any {
  return http.get(`/order-resource/1/bizOrderInvoice`, formdata);
}
//  通过开票订单号查询开票信息
export function invoiceDetail(invoiceOrderNumber: any): any {
  return http.get(`/order-resource/1/bizOrderInvoice/${invoiceOrderNumber}`);
}
//  发票审核
export function auditDetail(invoiceOrderNumList: any, state: any): any {
  return http.put(
    `/order-resource/1/bizOrderInvoice/invoiceOrderNumber/audit/${state}`,
    invoiceOrderNumList
  );
}
//  订单列表查询
export function orderPage(formdata: any): any {
  return http.get(`/order-resource/1/order/page`, formdata);
}
//  发票列表导出
export function listExport(formdata: any, invoiceOrderNumList: any): any {
  return http.download(
    `/order-resource/1/bizOrderInvoice/export/`,
    formdata,
    invoiceOrderNumList
  );
}

// 列表导出实物清单
export function goodsExport(
  payStartTime: string,
  payEndTime: string
): Promise<BaseResponse> {
  return request({
    url: `/order-resource/1/order/orderExport`,
    method: 'GET',
    responseType: 'blob',
    params: {
      payStartTime,
      payEndTime,
    },
  });
}
// 订购管理列表导出
export function orderListExport(
  paid: string,
  agencyType: string,
  orderSn: string,
  agencyName: string,
  orderStartTime: string,
  orderEndTime: string,
  payStartTime: string,
  payEndTime: string
): Promise<BaseResponse> {
  return request({
    url: `/order-resource/1/order/orderListExport`,
    method: 'GET',
    responseType: 'blob',
    params: {
      paid,
      agencyType,
      orderSn,
      agencyName,
      orderStartTime,
      orderEndTime,
      payStartTime,
      payEndTime,
    },
  });
}

//补单接口
export function manualCompensation(data): Promise<BaseResponse> {
  return request({
    url: `/order-resource/1/order/checkPaymentStatus/by/manage`,
    method: 'POST',
    data,
  });
}

//补单详情接口
export function getCompensateDetail(
  sequenceNbr: string
): Promise<BaseResponse> {
  return request({
    url: `/order-resource/1/orderOptLog/orderSeqNbr/${sequenceNbr}`,
    method: 'GET',
  });
}
